<template>
  <section id="triagerules">
    <!-- Triage rules Table Card -->
    <b-card no-body>
      <!-- Table Top -->
      <b-row>
        <b-col cols="12" md="12">
          <div class="d-flex align-items-center justify-content-end mt-2 mr-2">
            <b-button variant="primary">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span class="text-nowrap">Add rule</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <triage-rules-table :table-data="data.triagerules" class="mt-2 mt-md-2" />
    </b-card>
    <!--/ Triage rules Table Card -->
  </section>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import TriageRulesTable from "./TriageRulesTable.vue";

export default {
  data() {
    return {
      //selected: { title: 'Square' },
      optionUrgency: [{ title: "Urgent" }, { title: "Routine" }],
      optionAdvices: [{ title: "Contact GP" }, { title: "Call 111" }],
      optionExercises: [{ title: "Yes" }, { title: "No" }],
      selectedUrgency: [],
      selectedAdvices: [],
      selectedExercises: [],

      data: {
        triagerules: [
          {
            id: 1,
            name: "Frozen shoulder",
            conditions: ["Frozen shoulder", "RSI"],
            policies: [
              { urgency: "Urgent" },
              { advice: "Do this" },
              { exercises: "Shoulder RSI" },
            ],
            policiesElse: [
              { urgency: "Routine" },
              { advice: "Do that" },
              { exercises: "Shoulder RSI" },
            ],
          },
          {
            id: 2,
            name: "Shoulder arthrosis",
            conditions: ["Shoulder arthrosis"],
            policies: [{ urgency: "Routine" }, { advice: "Do this" }],
            policiesElse: [
              { urgency: "Routine" },
              { advice: "Do that" },
              { exercises: "Shoulder RSI" },
            ],
          },
          {
            id: 3,
            name: "CANS",
            conditions: ["CANS"],
            policies: [{ urgency: "Urgent" }, { advice: "Do this" }],
            policiesElse: [],
          },
        ],
      },
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormGroup,
    vSelect,
    TriageRulesTable,
    BButton,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.popover-body {
  color: #6e6b7b !important;
}
</style>