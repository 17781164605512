<template>
    <b-table v-if="tableData" hover :items="tableData" responsive :fields="fields" class="mb-0">
      <!-- id -->
      <template #cell(id)="data">
        <div class="font-weight-bolder">
          {{ data.item.id }}
        </div>
      </template>

      <!-- name -->
      <template #cell(name)="data">
        <router-link to="/triage-rule">{{ data.item.name }}</router-link>
      </template>

      <!-- conditions -->
      <template #cell(conditions)="data">
        <div class="batch_container">
          <b-badge
            variant="light-secondary"
            v-for="(condition, index) in data.item.conditions"
            :key="index"
          >
            {{ condition }}
          </b-badge>
        </div>
      </template>

      <!-- policy -->
      <template #cell(policies)="data">
        <div class="batch_container">
          <span
            v-for="(policy, index) in data.item.policies"
            :key="data.item.id + '_' + index"
          >
            <b-badge
              v-if="policy.urgency"
              variant="primary"
              v-b-popover.hover.top.v-primary="{ content: policy.urgency }"
              title="Urgency"
            >
              U
            </b-badge>
            <b-badge
              v-if="policy.advice"
              variant="info"
              v-b-popover.hover.top.v-info="{ content: policy.advice }"
              title="Advice"
            >
              A
            </b-badge>
            <b-badge
              v-if="policy.exercises"
              variant="dark"
              v-b-popover.hover.top.v-dark="{ content: policy.exercises }"
              title="Exercises"
            >
              E
            </b-badge>
          </span>
        </div>
      </template>

      <!-- policy else-->
      <template #cell(policiesElse)="data">
        <div class="batch_container">
          <span
            v-for="(policy, index) in data.item.policiesElse"
            :key="data.item.id + '_' + index"
          >
            <b-badge
              v-if="policy.urgency"
              variant="primary"
              v-b-popover.hover.top.v-primary="{ content: policy.urgency }"
              title="Urgency"
            >
              U
            </b-badge>
            <b-badge
              v-if="policy.advice"
              variant="info"
              v-b-popover.hover.top.v-info="{ content: policy.advice }"
              title="Advice"
            >
              A
            </b-badge>
            <b-badge
              v-if="policy.exercises"
              variant="dark"
              v-b-popover.hover.top.v-dark="{ content: policy.exercises }"
              title="Exercises"
            >
              E
            </b-badge>
          </span>
        </div>
      </template>

      <!-- actions -->
      <template #cell(actions)>

        <feather-icon
          icon="EditIcon"
          class="cursor-pointer"
          size="16"
        />
        <b-tooltip
          title="Edit"
          class="cursor-pointer"
        />        
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item>
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BImg,
  BBadge,
  VBPopover,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        { key: "id", label: "ID" },
        { key: "name", label: "NAME" },
        { key: "conditions", label: "CONDITION(S)" },
        { key: "policies", label: "POLICY" },
        { key: "policiesElse", label: "ELSE" },
        { key: "actions", label: "ACTIONS" },
      ],
    };
  },
  directives: {
    "b-popover": VBPopover,
    Ripple,
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

.batch_container .badge {
  margin: 0.25rem 0.5rem 0.25rem 0rem;
}
</style>
